<template>
    <div>
        <div class="font-sb font-12 m-b-sm">Cotações recebidas</div>
        <div class=" flex justify-between" style="height: 160px">
            <div class="m-t col-grow flex justify-between h-full" style="width: 100%; margin: auto">
                <e-window-table class="col-grow h-full"
                                :columns="['Cod. Remoção', 'Data', 'Custo', 'Reboquista', 'Status', 'Opções']"
                >
                    <e-tr v-for="(data, index) in model" :key="index">
                        <e-td><span>{{ data.codigo }}</span></e-td>
                        <e-td>{{ data.data.date|formatDate('dd/MM/yyyy') }}</e-td>
                        <e-td>R$ 210,00</e-td>
                        <e-td>AUTO SOCORRO PRIME</e-td>
                        <e-td></e-td>
                        <e-td><a><i class="erp-icon search min"></i> </a></e-td>
                    </e-tr>

                    <e-table-footer slot="footer" class="flex flex-center">
                        <e-table-footer-result class="m-r text-right">Total de cotações = {{model.length}}</e-table-footer-result>
                    </e-table-footer>
                </e-window-table>
            </div>
        </div>
    </div>
</template>

<script>
    import {ETableFooter, ETableFooterResult, ETd, ETr, EWindowTable} from 'uloc-vue-plugin-erp'
    import windowSolicitacao from '../../../windows/solicitacao'

    export default {
        name: 'RecebimentoCotacao',
        props: {
            solicitacoes: {
                required: true
            }
        },
        components: {
            EWindowTable,
            ETr,
            ETd,
            ETableFooter,
            ETableFooterResult
        },
        data () {
            return {
                model: this.solicitacoes
            }
        },
        watch: {
            solicitacoes (v) {
                console.log(v)
                this.$nextTick(() => {
                    this.model = v
                })
            }
        },
        methods: {
            getUf (uf) {
                if (typeof uf === 'object') {
                    return uf.nome || uf
                }
                return uf
            },
            status (s) {
                if (typeof s !== 'undefined') {
                    return String(s)
                }
                return false
            },
            openSolicitacao: windowSolicitacao
        }
    }
</script>

<template>
    <window-content v-if="!loading && !errors && !success">
        <window-header>
            <div class="wrapper-sm">
                <div class="row erp-row erp-row-col-md">
                    <div class="col">
                        <erp-s-field
                                view="lr"
                                label="ID:"
                                label-width="40px"
                        >
                            <erp-input autofocus simple-border v-model="cotacao.id" />
                        </erp-s-field>
                    </div>

                    <div class="col" style="max-width: 240px">
                        <erp-s-field
                                view="lr"
                                label="Data Limite:"
                        >
                            <datetime-input v-model="cotacao.dataLimite" simpleBorder />
                        </erp-s-field>
                    </div>

                    <div class="col" style="max-width: 240px">
                        <erp-s-field
                                view="lr"
                                label="Limite  Coleta"
                        >
                            <erp-input simple-border disable v-model="cotacao.dataColeta" :after="[{icon: 'date_range', handler () {}}]"/>
                        </erp-s-field>
                    </div>

                    <div class="col flex justify-end">
                        <erp-s-field
                                view="lr"
                                label="Limite Entrega:"
                        >
                            <erp-input simple-border disable v-model="cotacao.limiteEntrega" :after="[{icon: 'date_range', handler () {}}]"/>
                        </erp-s-field>
                    </div>

                </div>

                <div class="row erp-row erp-row-col-md m-t-sm m-b">
                    <div class="col">
                        <erp-s-field
                                view="lr"
                                label="Bem:"
                                label-width="40px"
                        >
                            <bem-input v-model="cotacao.bem" />
                        </erp-s-field>
                    </div>

                    <div class="col">
                        <erp-s-field
                                view="lr"
                                label="Comitente:"
                        >
                            <comitente-input v-model="cotacao.comitente" />
                        </erp-s-field>
                    </div>

                    <div class="col" style="max-width: 180px">
                        <status-select :disable="!cotacao.id" label="Status:" :helper="statusHelper" placeholder="Selecione"
                                       field-view="lr"
                                       v-model="cotacao.status" />
                    </div>
                </div>

            </div>
        </window-header>
        <window-body>
            <div class="wrapper-md m-t-lg">
                <form v-on:submit.stop.prevent="">
                    <div class="row">
                        <div class="col m-r-xs" style="max-width: 200px">
                            <uf-select required :error="solicitacaoError && !solicitacao.origem.uf" label-width="60px" helper="UF"
                                       helper-position="lt" field-view="lr" label="Origem:"
                                       v-model="solicitacao.origem.uf" />
                        </div>

                        <div class="col m-r-xs" style="max-width: 200px">
                            <cidade-select :disable="!solicitacao.origem.uf" :uf="solicitacao.origem.uf"
                                           :error="solicitacaoError && !solicitacao.origem.cidade"
                                           helper="Cidade" helper-position="lt" field-view="lr" no-label
                                           v-model="solicitacao.origem.cidade" />
                        </div>

                        <div class="col">
                            <destino-select :disable="!solicitacao.origem.cidade" field-view="lr" helper="Localização"
                                            helper-position="lt" no-label
                                            :cidade="solicitacao.origem.cidade"
                                            v-model="solicitacao.origem.localizacao"
                                            :local="solicitacao.origem"
                                            :error="solicitacaoError && !solicitacao.origem.localizacao"
                                            @showMap="mostraMapaLocalOrigem"
                            />
                        </div>

                    </div>

                    <div class="row m-t-sm">
                        <div class="col m-r-xs" style="max-width: 200px">
                            <uf-select label-width="60px" field-view="lr" label="Destino:" v-model="solicitacao.destino.uf"
                                       :error="solicitacaoError && !solicitacao.destino.uf" />
                        </div>

                        <div class="col m-r-xs" style="max-width: 200px">
                            <cidade-select :disable="!solicitacao.destino.uf" :uf="solicitacao.destino.uf" field-view="lr" no-label
                                           v-model="solicitacao.destino.cidade"
                                           :error="solicitacaoError && !solicitacao.destino.cidade" />
                        </div>

                        <div class="col">
                            <destino-select :disable="!solicitacao.destino.cidade" field-view="lr" no-label
                                            :cidade="solicitacao.destino.cidade"
                                            v-model="solicitacao.destino.localizacao"
                                            :local="solicitacao.destino"
                                            :error="solicitacaoError && !solicitacao.destino.localizacao"
                                            @showMap="mostraMapaLocalDestino"
                            />
                        </div>
                    </div>

                    <local-remocao v-if="showLocalMap" ref="mapLocal" />

                    <div class="row justify-between m-t-sm">
                        <div class="col" style="max-width: 671px; min-width: 300px">
                            <erp-s-field
                                    view="lr"
                                    label="Reboquista:"
                                    label-width="60px"
                                    :error="solicitacaoError && !solicitacao.reboquista"
                            >
                                <reboquista-input :trajeto="[solicitacao.origem, solicitacao.destino]"
                                                  v-model="solicitacao.reboquista" />
                                <!-- TODO: Criar filtro de reboquistas baseado no trajeto -->
                            </erp-s-field>
                        </div>
                        <div class="col m-l-md flex">
                            <small class="font-11">Somente para enviar cotação particular</small>
                        </div>
                    </div>

                    <div class="row m-t-sm">
                        <div class="col" style="max-width: 182px">
                            <erp-s-field
                                    view="lr"
                                    label="Custo predentido:"
                                    label-width="60px"
                                    wrap
                            >
                                <erp-input autofocus simple-border v-model="solicitacao.custoPretendido" readonly />
                            </erp-s-field>
                        </div>
                    </div>

                    <div class="row m-t-sm">
                        <div class="col" style="max-width: 310px">
                            <erp-s-field
                                    view="lr"
                                    label="Custo:"
                                    label-width="60px"
                            >
                                <helper-input-btn>
                                    <erp-input disable placeholder="-" slot="input" v-model="solicitacao.custo" />
                                    <a @click="lancarCustoManual" class="no-select"><small class="text-black small flex">
                                        <u-icon name="external-link-alt" color="black" style="font-size: 12px" class="m-r-xs" type="fa" />
                                        alterar manualmente</small></a>

                                </helper-input-btn>
                            </erp-s-field>
                        </div>
                    </div>

                    <div class="row m-t">
                        <div class="col m-r-md" style="max-width: 436px;">
                            <erp-s-field
                                    view="lr"
                                    label="Observações Internas:"
                                    label-width="60px"
                                    wrap
                            >
                                <textarea v-model="solicitacao.observacoesInternas" class="full-width wrapper-xs" rows="4"></textarea>
                            </erp-s-field>
                        </div>
                    </div>

                    <div class="m-t-lg">
                        <recebidas :solicitacoes="cotacao.solicitacoes" />
                    </div>
                </form>
            </div>
        </window-body>

        <window-footer class="text-right">
            <div class="inline-block window-actions">
                <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
                       @click="$uloc.window.close($root.wid)" />
                <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
                       @click="save"
                       v-shortkey="['f2']" @shortkey.native="save" />
            </div>
        </window-footer>
    </window-content>
</template>

<script>
    import {
        WindowContent,
        WindowHeader,
        WindowBody,
        WindowFooter,
        ErpSField,
        ErpInput,
        HelperInputBtn
    } from 'uloc-vue-plugin-erp'
    import DatetimeInput from '../../../../reuse/input/Datetime'
    import BemInput from '../../../bem/helpers/input/BemInput'
    import ComitenteInput from '../../../comitente/helpers/input/ComitenteInput'
    import StatusSelect from '../include/StatusSelect'
    import UfSelect from '../include/UfSelect'
    import CidadeSelect from '../include/CidadeSelect'
    import DestinoSelect from '../include/DestinoSelect'
    import ReboquistaInput from '../../../reboquista/helpers/input/ReboquistaInput'
    import Recebidas from './CotacaoComponents/Recebidas'
    import LocalRemocao from '../maps/Local'

    let mockSolicitacao = {
        id: null,
        origem: {
            uf: 'MG',
            cidade: null,
            localizacao: null,
            endereco: null,
            lat: null,
            lng: null
        },
        destino: {
            uf: null,
            cidade: null,
            localizacao: null,
            endereco: null,
            lat: null,
            lng: null
        },
        reboquista: null,
        custoPretendido: null,
        custo: null,
        observacoesInternas: null
    }

    export default {
        name: 'CotacaoWindow',
        data () {
            return {
                loading: !!this.id,
                errors: null,
                success: false,
                cotacao: {
                    id: null,
                    dataLimite: null,
                    dataColeta: null,
                    limiteEntrega: null,
                    bem: null,
                    comitente: null,
                    status: null,
                    solicitacoes: []
                },
                solicitacao: JSON.parse(JSON.stringify(mockSolicitacao)),
                solicitacaoError: false,
                showLocalMap: false
            }
        },
        computed: {
            statusHelper () {
                let status = 'Aceito pelo reboquista'
                if (this.cotacao.id) {
                    status = ''
                }
                return status
            }
        },
        methods: {
            lancarCustoManual () {
                this.$uloc.dialog({
                    title: 'Permissão negada!',
                    message: `Você precisa de permissão de gerência para efetuar esta operação.`
                })
            },
            save () {
                console.log('Save')
                this.loading = true
                window.setTimeout(() => {
                    this.success = true
                    this.$nextTick(() => {
                        this.$refs.timer.start()
                    })
                }, 1000)
            },
            mostraMapaLocal (local, tipo) {
                console.log('Mostra', local, tipo)
                this.showLocalMap = true
                this.$nextTick(() => {
                    this.$refs.mapLocal.show(
                        local, tipo === 'origem' ? this.confirmarLocalizacaoOrigem : this.confirmarLocalizacaoDestino
                    )
                })
            },
            mostraMapaLocalOrigem (local) {
                this.mostraMapaLocal(local, 'origem')
            },
            mostraMapaLocalDestino (local) {
                this.mostraMapaLocal(local, 'destino')
            },
            confirmarLocalizacaoOrigem (location) {
                console.log('Confirm location Origem: ', location)
                this.solicitacao.origem.endereco = location.address
                this.solicitacao.origem.lat = location.latLng.lat
                this.solicitacao.origem.lng = location.latLng.lng
            },
            confirmarLocalizacaoDestino (location) {
                console.log('Confirm location Destino: ', location)
                this.solicitacao.destino.endereco = location.address
                this.solicitacao.destino.lat = location.latLng.lat
                this.solicitacao.destino.lng = location.latLng.lng
            }
        },
        components: {
            WindowContent,
            WindowHeader,
            WindowBody,
            WindowFooter,
            ErpSField,
            ErpInput,
            DatetimeInput,
            BemInput,
            ComitenteInput,
            StatusSelect,
            UfSelect,
            CidadeSelect,
            DestinoSelect,
            ReboquistaInput,
            HelperInputBtn,
            Recebidas,
            LocalRemocao
        }
    }
</script>
